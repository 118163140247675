<template>
  <entity-form
    :id="discount ? discount.id : null"
    module-name="discountsList"
    :model="form"
    :before-submit="beforeSubmit"
    @submit="onSubmit"
  >
    <b-form-row>
      <b-col
        md="8"
        xs="12"
      >
        <b-form-group>
          <label>Name</label>
          <b-form-input
            ref="discount-name"
            v-model="form.name"
            type="text"
            placeholder="Discount name"
            required
          />
        </b-form-group>
      </b-col>
      <b-col
        md="4"
        xs="12"
        class="justify-content-center"
      />
      <b-form-checkbox
        v-model="form.active"
        class="custom-control-primary"
      >
        Activate discount
      </b-form-checkbox>
    </b-form-row>

    <b-form-row>
      <b-col
        md="6"
        xs="12"
      >
        <label>Absolute value</label>
        <b-input-group>
          <b-input-group-prepend is-text>
            <feather-icon icon="DollarSignIcon" />
          </b-input-group-prepend>
          <b-form-input
            ref="absolute-value"
            v-model.number="form.absolute_value"
            type="number"
            :disabled="disableAbsoluteValue"
            min="0"
          />
        </b-input-group>
      </b-col>

      <b-col
        md="6"
        xs="12"
      >
        <label>Relative value</label>
        <b-input-group>
          <b-input-group-prepend is-text>
            <feather-icon icon="PercentIcon" />
          </b-input-group-prepend>
          <b-form-input
            ref="absolute-value"
            v-model.number="form.relative_value"
            type="number"
            :disabled="disableRelativeValue"
            min="0"
            max="100"
          />
        </b-input-group>
      </b-col>
    </b-form-row>

    <b-form-row
      class="mt-1 p-50"
    >
      <label>Discount only for users registered after:</label>
      <div class="input-group">
        <flat-pickr
          v-model="form.registeredAfter"
          label="Discount only for users registered after (UTC+0):"
          class="form-control"
          :config="{dateFormat: 'Y-m-d', time_24hr: true, defaultHour: 0}"
        />
        <b-button
          variant="outline-primary"
          @click.prevent="form.registeredAfter = null"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
    </b-form-row>
  </entity-form>
</template>
<script>
import {
  BFormGroup, BFormInput, BInputGroupPrepend, BCol, BFormRow, BInputGroup,
  BFormCheckbox, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FlatPickr from 'vue-flatpickr-component'
import { formatCurrency } from '@/plugins/formaters'
import EntityForm from '@/components/ui/forms/EntityForm.vue'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    BFormRow,
    BCol,
    BInputGroupPrepend,
    BButton,
    EntityForm,
    FlatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    discount: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      form: {
        name: this.discount ? this.discount.name : '',
        absolute_value: this.discount ? this.discount.absolute_value : 0,
        relative_value: this.discount ? this.discount.relative_value : 0,
        active: this.discount ? this.discount.active : true,
        registeredAfter: this.discount ? this.discount.registeredAfter : null,
      },
    }
  },
  computed: {
    disableAbsoluteValue() {
      return this.form.relative_value > 0
    },
    disableRelativeValue() {
      return this.form.absolute_value > 0
    },
  },
  methods: {
    formatCurrency,
    beforeSubmit() {
      if ((this.form.absolute_value === 0 && this.form.relative_value === 0) || this.form.name.length === 0) {
        // eslint-disable-next-line no-alert
        alert('Do not forget set value of discount and name!')
        return false
      }
      this.form.absolute_value = Number(this.form.absolute_value)
      this.form.relative_value = Number(this.form.relative_value)

      // one of discount value have to be null
      if (this.form.absolute_value === 0) this.form.absolute_value = null
      if (this.form.relative_value === 0) this.form.relative_value = null

      return true
    },
    onSubmit(data) {
      this.$emit('submit', data)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
