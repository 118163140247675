<template>
  <div>
    <b-row class="justify-content-between mb-2">
      <b-col lg="6">
        <b-form-group
          class="mb-0 mt-1"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="search"
              type="search"
              placeholder="Search by name"
              @keyup.enter="advancedFilter.search = search"
            />
            <b-input-group-append>
              <b-button
                variant="outline-info"
                @click="clearSearch"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                variant="outline-primary"
                :disabled="!search"
                @click="advancedFilter.search = search"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col class="justify-content-start">
        <b-form-checkbox
          v-model="advancedFilter.search_in_codes"
          class="mt-1"
        >
          Search in codes
        </b-form-checkbox>
      </b-col>
      <b-col
        lg="5"
        class="justify-content-end mt-1"
      >
        <b-button
          variant="primary"
          @click="onCreateDiscount"
        >
          Create discount
        </b-button>
      </b-col>
    </b-row>

    <data-table
      :module-name="'discountsList'"
      :fields="fields"
      :filters="advancedFilter"
      sort-by="createdAt"
      :sort-desc="false"
      :actions="actions"
      deletable
      @detail="onDetail"
      @state="onChangeState"
      @edit="onEditDiscount"
    >

      <template #cell(name)="data">
        <b-link :to="{ name: 'discount-detail', params: { id: data.item.id } }">
          {{ data.item.name }}
        </b-link>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(state)="data">
        <b-badge :variant="data.item.active ? 'primary' : 'danger'">
          {{ data.item.active ? 'active' : 'disabled' }}
        </b-badge>
      </template>

      <template #cell(registeredAfter)="data">
        <b-badge
          v-if="data.item.registeredAfter === null"
          variant="danger"
        >
          inactive option
        </b-badge>
        <span v-else>
          {{ formatDate(data.item.registeredAfter) }}
        </span>
      </template>

    </data-table>

    <b-modal
      id="discount-modal"
      :title="title"
      :no-close-on-backdrop="true"
      size="lg"
      hide-footer
    >
      <discount-form
        :discount="discountEdit"
        @submit="onEditSubmit"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BInputGroup, BInputGroupAppend, BFormInput, BFormGroup, BBadge, BModal, BLink, BFormCheckbox,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import Swal from 'sweetalert2'
import DataTable from '@/components/ui/tables/DataTable.vue'
import table from '@/mixins/table'
import { formatDateTime, formatDate } from '@/plugins/formaters'
import store from '@/store'
import DiscountForm from '@/components/forms/discount/DiscountForm.vue'

const actions = [
  {
    text: 'Discount details',
    icon: 'SearchIcon',
    emit: 'detail',
  },
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Change state',
    icon: 'Edit2Icon',
    emit: 'state',
  },
]

export default {
  components: {
    DiscountForm,
    DataTable,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormGroup,
    BBadge,
    BModal,
    BLink,
    BFormCheckbox,
  },
  mixins: [table],
  data() {
    return {
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'value_string',
          sortable: false,
        },
        {
          key: 'createdAt',
          sortable: true,
        },
        {
          key: 'state',
          sortable: true,
        },
        {
          key: 'registeredAfter',
          sortable: true,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
      filterOn: ['name'],
      filter: null,
      advancedFilter: { search: '', search_in_codes: false },
      search: '',
      actions,
      discountEdit: null,
      title: '',
    }
  },
  computed: {
    ...get('discountsList', ['data']),
  },
  methods: {
    formatDateTime,
    formatDate,
    onEditSubmit() {
      this.$bvModal.hide('discount-modal')
    },
    onCreateDiscount() {
      this.title = 'Create discount'
      this.discountEdit = null
      this.$bvModal.show('discount-modal')
    },
    onEditDiscount(item) {
      this.title = 'Edit discount'
      this.discountEdit = item
      this.$bvModal.show('discount-modal')
    },
    onDetail(item) {
      this.$router.push({ name: 'discount-detail', params: { id: item.id } })
    },
    onChangeState(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `Do you want change state of discount to ${!item.active ? 'active' : 'disabled'}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          this.isLoading = true
          store.dispatch('discountsList/update', { id: item.id, data: { active: !item.active } })
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
    clearSearch() {
      this.advancedFilter.search = ''
      this.search = ''
    },
  },
}
</script>
